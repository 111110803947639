import Simple from '@/components/task-templates/Simple'

/*
? экранирование и спецсимволы
? декоратор: логин и пароль
*/

export default [
  {
    component: Simple,
    name: 'Hello, world!',
    text: 'Ничего не дано. Выведите строку \'Hello, world!\' (без кавычек)',
    note: null,
    input: 'Ничего',
    output: 'Строка',
    examples: [
      {
        input: '',
        output: 'Hello, world!'
      }
    ]
  },
  {
    component: Simple,
    name: 'Конкатенация',
    text: 'Дана строка \\(name\\). Выведите строку \'Hello, {name}!\', вместо {name} подставив значение переменной с помощью конкатенации строк',
    note: 'Конкатенация - то же, что склеивание. Делается с помощью +',
    input: 'Строка \\(name\\)',
    output: 'Строка',
    examples: [
      {
        input: "'world'",
        output: 'Hello, world!'
      },
      {
        input: "'Simon'",
        output: 'Hello, Simon!'
      },
      {
        input: "'Jerry'",
        output: 'Hello, Jerry!'
      }
    ]
  },
  {
    component: Simple,
    name: 'Шаблонные строки',
    text: 'Дана строка \\(name\\). Выведите строку \'Hello, {name}!\', вместо {name} подставив значение переменной с помощью шаблонной строки',
    note: 'Шаблонная строка - строка, записанная с косыми кавычками (``). В неё можно вставлять выражения с помощью синтаксиса ${}',
    input: 'Строка \\(name\\)',
    output: 'Строка',
    examples: [
      {
        input: "'world'",
        output: 'Hello, world!'
      },
      {
        input: "'Simon'",
        output: 'Hello, Simon!'
      },
      {
        input: "'Jerry'",
        output: 'Hello, Jerry!'
      }
    ]
  },
  {
    component: Simple,
    name: 'Приветствие с именем',
    text: 'Даны строки \\(greeting\\) и \\(name\\). Выведите строку \'{greeting}, {name}!\', подставив значения переменных с помощью шаблонной строки',
    note: null,
    input: 'Строки \\(greeting\\) и \\(name\\)',
    output: 'Строка',
    examples: [
      {
        input: "'Hello', 'world'",
        output: 'Hello, world!'
      },
      {
        input: "'Hi', 'John'",
        output: 'Hi, John!'
      },
      {
        input: "'Salut', 'Mr. Smith'",
        output: 'Salut, Mr. Smith!'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вывод уравнения',
    text: 'Даны целые числа \\(a\\) и \\(b\\). Выведите строку \'{a} + {b} = {a + b}\', подставив значения переменных с помощью шаблонной строки',
    note: null,
    input: 'Строки \\(a\\) и \\(b\\)',
    output: 'Строка',
    examples: [
      {
        input: '2, 4',
        output: '2 + 4 = 6'
      },
      {
        input: '5, 3',
        output: '5 + 3 = 8'
      },
      {
        input: '10, 27',
        output: '10 + 27 = 37'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первый и последний символы',
    text: 'Дана строка \\(s\\). Выведите её первый и последний символы',
    note: 'Синтаксис аналогичен массивам',
    input: 'Строка \\(s\\)',
    output: 'Два символа через пробел',
    examples: [
      {
        input: "'Abra'",
        output: 'A a'
      },
      {
        input: "'Cadabra'",
        output: 'C a'
      },
      {
        input: "' . '",
        output: '   '
      }
    ]
  },
  {
    component: Simple,
    name: 'Сортировка строк',
    text: 'Даны строки \\(a\\) и \\(b\\). Упорядочьте их в лексикографическом порядке и выведите через пробел',
    note: 'Операторы сравнения для строк работают как раз в лексикографическом порядке',
    input: 'Строки \\(a\\) и \\(b\\)',
    output: 'После упорядочивания',
    examples: [
      {
        input: "'about', 'baobab'",
        output: 'about baobab'
      },
      {
        input: "'about', 'Baobab'",
        output: 'Baobab about'
      },
      {
        input: "'aaaa', 'aaaA'",
        output: 'aaaA aaaa'
      },
      {
        input: "'AAA', 'AAAAA'",
        output: 'AAA AAAAA'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сравнение строк',
    text: 'Даны строки \\(a\\) и \\(b\\). Если строки равны, вывести 0, если \\(a\\) меньше, вывести -1, в противном случае 1',
    note: 'Когда говорим о строках больше/меньше, подразумеваем всегда сравнение в лексикографическом порядке',
    input: 'Строки \\(a\\) и \\(b\\)',
    output: '0, -1 или 1',
    examples: [
      {
        input: "'Test', 'Tes'",
        output: '1'
      },
      {
        input: "'Test', 'Test'",
        output: '0'
      },
      {
        input: "'Test', 'test'",
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сравнение строк',
    text: 'Дана строка \\(s\\), состоящая из одного символа. Если это цифра, вывести digit, если строчная буква, вывести lower, если заглавная, вывести upper, в противном случае вывести other',
    note: 'Для этого можно воспользоваться сравнением с начальными и конечными символами диапазона',
    input: 'Строка \\(s\\)',
    output: 'Тип символа',
    examples: [
      {
        input: "'x'",
        output: 'lower'
      },
      {
        input: "'W'",
        output: 'upper'
      },
      {
        input: "'#'",
        output: 'other'
      },
      {
        input: "'3'",
        output: 'digit'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество пробелов',
    text: 'Дана строка \\(s\\). Выведите количество пробелов в ней',
    note: 'Можно использовать цикл for-of',
    input: 'Строка \\(s\\)',
    output: 'Количество пробелов',
    examples: [
      {
        input: "'Count of spaces is 4'",
        output: '4'
      },
      {
        input: "'   '",
        output: '3'
      },
      {
        input: "'Zero one two'",
        output: '2'
      },
      {
        input: "'Zero'",
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Надёжный пароль',
    text: 'Дана строка \\(s\\). Проверьте, является ли она надёжным паролем. Надёжным считается пароль, длина которого не меньше 8 символов, при этом он содержит хотя бы одну цифру, хотя бы одну строчную латинскую букву, хотя бы одну заглавную латинскую букву и хотя бы один символ, не являющийся цифрой или латинской буквой',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'true или false',
    examples: [
      {
        input: "'strongPassw0rd'",
        output: 'false'
      },
      {
        input: "'strongPa$$word'",
        output: 'false'
      },
      {
        input: "'strongP@ssw0rd'",
        output: 'true'
      },
      {
        input: "'Pa$$123'",
        output: 'false'
      },
      {
        input: "'Pa$$1234'",
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Возрастная группа',
    text: 'Дано число \\(a\\) - возраст человека. Определите, к какой возрастной группе он относится, если возрастные группы определяются по десяткам: 0-9, 10-19, 20-29 и т. д.',
    note: null,
    input: 'Число \\(a\\)',
    output: 'Возрастная группа',
    examples: [
      {
        input: '5',
        output: '0-9'
      },
      {
        input: '30',
        output: '30-39'
      },
      {
        input: '101',
        output: '100-109'
      }
    ]
  },
  {
    component: Simple,
    name: 'Массив из строки',
    text: 'Дана строка \\(s\\). Выведите массив из её символов. Для этого воспользуйтесь функцией Array.from',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Массив символов',
    examples: [
      {
        input: "'Test'",
        output: "['T', 'e', 's', 't']"
      },
      {
        input: "'12 34'",
        output: "['1', '2', ' ', '3', '4']"
      },
      {
        input: "''",
        output: '[]'
      }
    ]
  },
  {
    component: Simple,
    name: 'Число из строки',
    text: 'Дано число в виде строки \\(s\\). Выведите число на 1 больше',
    note: 'Для преобразования типа можно использовать унарный +',
    input: 'Строка \\(s\\)',
    output: 'Число + 1',
    examples: [
      {
        input: "'4'",
        output: '5'
      },
      {
        input: "'10'",
        output: '11'
      },
      {
        input: "'321'",
        output: '322'
      }
    ]
  },
  {
    component: Simple,
    name: 'Разбивка на массив по подстроке',
    text: 'Дана строка \\(s\\). Выведите массив из частей этой строки, отделённых пробелами. Для этого воспользуйтесь методом строк split',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Получившийся массив',
    examples: [
      {
        input: "'First second third'",
        output: "['First', 'second', 'third']"
      },
      {
        input: "'   '",
        output: "['', '', '', '']"
      },
      {
        input: "'12 34 567'",
        output: "['12', '34', '567']"
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые символы частей',
    text: 'Дана строка \\(s\\). Выведите строку, состоящую из первых символов частей, полученных делением строки \\(s\\) по пробелам',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Строка из первых символов',
    examples: [
      {
        input: "'abra cada bra'",
        output: 'acb'
      },
      {
        input: "'super puper string'",
        output: 'sps'
      },
      {
        input: "'123 456'",
        output: '14'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма чисел в строке',
    text: 'Дана строка \\(s\\), состоящая из чисел, разделённых пробелами. Выведите сумму чисел из этой строки',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Сумма чисел',
    examples: [
      {
        input: "'2 2'",
        output: '4'
      },
      {
        input: "'10 20 15'",
        output: '45'
      },
      {
        input: "'1 11 111 1111'",
        output: '1234'
      }
    ]
  },
  {
    component: Simple,
    name: 'Склеивание массива строк',
    text: 'Дан массив строк \\(a\\). Выведите строку, состоящую из всех строк массива \\(a\\)',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Строка',
    examples: [
      {
        input: "['1', '2', '3', '4']",
        output: '1234'
      },
      {
        input: "['a', 'b', 'c']",
        output: 'abc'
      },
      {
        input: "['a', '1', 'b', '2']",
        output: 'a1b2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Склеивание массива строк с разделителем',
    text: 'Дан массив строк \\(a\\) и строка \\(d\\). Выведите строку, состоящую из всех строк массива \\(a\\), между каждой парой вставляя строку \\(d\\)',
    note: null,
    input: '\\(a\\) - массив строк, \\(d\\) - строка',
    output: 'Строка',
    examples: [
      {
        input: "[], ' + '",
        output: ''
      },
      {
        input: "['1', '2', '3', '4'], ' + '",
        output: '1 + 2 + 3 + 4'
      },
      {
        input: "['a', 'b', 'c'], ', '",
        output: 'a, b, c'
      },
      {
        input: "['a', '1', 'b', '2'], ' '",
        output: 'a 1 b 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Склеивание массива с join',
    text: 'Дан массив строк \\(a\\) и строка \\(d\\). Выведите строку, состоящую из всех строк массива \\(a\\), между каждой парой вставляя строку \\(d\\), используя метод массива join',
    note: null,
    input: '\\(a\\) - массив строк, \\(d\\) - строка',
    output: 'Строка',
    examples: [
      {
        input: "[], ' + '",
        output: ''
      },
      {
        input: "['1', '2', '3', '4'], ' + '",
        output: '1 + 2 + 3 + 4'
      },
      {
        input: "['a', 'b', 'c'], ', '",
        output: 'a, b, c'
      },
      {
        input: "['a', '1', 'b', '2'], ' '",
        output: 'a 1 b 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удаление цифр',
    text: 'Дана строка \\(s\\). Выведите строку, содержащую все символы \\(s\\), кроме цифр',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Строка',
    examples: [
      {
        input: "'Delete a11 digit7'",
        output: 'Delete a digit'
      },
      {
        input: "'12-34'",
        output: '-'
      },
      {
        input: "'No digits'",
        output: 'No digits'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс первого вхождения - indexOf',
    text: 'Дана строка \\(s\\). Выведите индекс первого вхождения подстроки \'sub\'. Если такой нет, выведите -1. Для этого использовать метод строки indexOf',
    note: 'Подстрока - это часть строки. Индексом вхождения является индекс первого символа из подстроки',
    input: 'Строка \\(s\\)',
    output: 'Индекс первого вхождения',
    examples: [
      {
        input: "'Substring sub is here'",
        output: '10'
      },
      {
        input: "'sub sub substring'",
        output: '0'
      },
      {
        input: "'First substring only'",
        output: '6'
      },
      {
        input: "'Zero'",
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс второго вхождения',
    text: 'Дана строка \\(s\\). Выведите индекс второго вхождения подстроки \'sub\'. Если такой нет, выведите -1',
    note: 'Можно использовать метод indexOf с двумя параметрами. Вторым параметром указывается индекс, с которого начинается поиск',
    input: 'Строка \\(s\\)',
    output: 'Индекс второго вхождения',
    examples: [
      {
        input: "'sub sub'",
        output: '4'
      },
      {
        input: "'sub sub substring'",
        output: '4'
      },
      {
        input: "'First substring only'",
        output: '-1'
      },
      {
        input: "'Zero'",
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс последнего вхождения - lastIndexOf',
    text: 'Дана строка \\(s\\). Выведите индекс последнего вхождения подстроки \'sub\'. Если такой нет, выведите -1. Для этого воспользоваться методом строки lastIndexOf',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Индекс последнего вхождения',
    examples: [
      {
        input: "'sub sub'",
        output: '4'
      },
      {
        input: "'sub sub substring'",
        output: '8'
      },
      {
        input: "'First substring only'",
        output: '6'
      },
      {
        input: "'Zero'",
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Индекс предпоследнего вхождения',
    text: 'Дана строка \\(s\\). Выведите индекс предпоследнего вхождения подстроки \'sub\'. Если такой нет, выведите -1',
    note: 'Можно использовать метод lastIndexOf с двумя параметрами. Вторым параметром указывается индекс, с которого начинается поиск влево',
    input: 'Строка \\(s\\)',
    output: 'Индекс предпоследнего вхождения',
    examples: [
      {
        input: "'sub sub'",
        output: '0'
      },
      {
        input: "'sub sub substring'",
        output: '4'
      },
      {
        input: "'First substring only'",
        output: '-1'
      },
      {
        input: "'Zero'",
        output: '-1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество вхождений подстроки',
    text: 'Дана строка \\(s\\). Выведите количество вхождений подстроки \'sub\'',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Количество вхождений',
    examples: [
      {
        input: "'sub and sub and another sub'",
        output: '3'
      },
      {
        input: "'sub only once'",
        output: '1'
      },
      {
        input: "'no one'",
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез - slice',
    text: 'Дана строка \\(s\\). Выведите строку из символов со второго по четвёртый включтельно. Воспользоваться методом строки slice',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Срез',
    examples: [
      {
        input: "'abcd'",
        output: 'bcd'
      },
      {
        input: "'Test string'",
        output: 'est'
      },
      {
        input: "'First-second'",
        output: 'irs'
      }
    ]
  },
  {
    component: Simple,
    name: 'Срез со второго по предпоследний символ',
    text: 'Дана строка \\(s\\). Выведите строку из символов со второго по предпоследний включтельно',
    note: 'В метод slice можно передавать отрицательные числа. Это будет значить отсчёт с конца строки',
    input: 'Строка \\(s\\)',
    output: 'Срез',
    examples: [
      {
        input: "'abcd'",
        output: 'bc'
      },
      {
        input: "'Test string'",
        output: 'est strin'
      },
      {
        input: "'First-second'",
        output: 'irst-secon'
      }
    ]
  },
  {
    component: Simple,
    name: 'Отрезаем n первых символов',
    text: 'Дана строка \\(s\\) и число \\(n\\) (\\(0 \\le n < s.length\\)). Выведите строку из символов с индекса \\(n\\) до конца строки',
    note: 'Если в метод slice не передавать второй параметр - это то же, что передать длину строки',
    input: 'Строка \\(s\\), целое число \\(n\\)',
    output: 'Срез',
    examples: [
      {
        input: "'abcd', 2",
        output: 'cd'
      },
      {
        input: "'Test string', 5",
        output: 'string'
      },
      {
        input: "'First-second', 6",
        output: 'second'
      }
    ]
  },
  {
    component: Simple,
    name: 'Отрезаем n последних символов',
    text: 'Дана строка \\(s\\) и число \\(n\\) (\\(0 \\le n \\le s.length\\)). Выведите строку, полученную удалением последних \\(n\\) символов строки \\(s\\)',
    note: null,
    input: 'Строка \\(s\\), целое число \\(n\\)',
    output: 'Срез',
    examples: [
      {
        input: "'abcd', 2",
        output: 'ab'
      },
      {
        input: "'Test string', 3",
        output: 'Test str'
      },
      {
        input: "'First-second', 7",
        output: 'First'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первая половина',
    text: 'Дана строка \\(s\\). Выведите только первую половину строки (без центрального символа, если длина нечётная)',
    input: 'Строка \\(s\\)',
    output: 'Полученная строка',
    note: null,
    examples: [
      {
        input: "'abcd'",
        output: 'ab'
      },
      {
        input: "'abcdefg'",
        output: 'abc'
      },
      {
        input: "'1'",
        output: ''
      }
    ]
  },
  {
    component: Simple,
    name: 'Вторая половина',
    text: 'Дана строка \\(s\\). Выведите только вторую половину строки (вместе с центральным символом, если длина нечётная)',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abc'",
        output: 'bc'
      },
      {
        input: "'abcd'",
        output: 'cd'
      },
      {
        input: "'qwert'",
        output: 'ert'
      }
    ]
  },
  {
    component: Simple,
    name: 'Склеивание частей',
    text: 'Даны строка \\(s1\\), число \\(n1\\), строка \\(s2\\) и число \\(n2\\). Выведите строку из первых \\(n1\\) символов строки \\(s1\\) и последних \\(n2\\) символов строки \\(s2\\)',
    note: null,
    input: 'Cтрока \\(s1\\), число \\(n1\\), строка \\(s2\\) и число \\(n2\\). \\(n1\\), \\(n2\\) - целые неотрицатльные, не превышают длину своих строк',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abcd', 2, 'efgh', 2",
        output: 'abgh'
      },
      {
        input: "'1 is one', 1, 'first', 2",
        output: '1st'
      }
    ]
  },
  {
    component: Simple,
    name: 'Между дефисами',
    text: 'Дана строка \\(s\\), содержащая один или более дефисов. Выведите часть между первым и последним дефисом',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'First-second-third'",
        output: 'second'
      },
      {
        input: "'1-2-3-4-5'",
        output: '2-3-4'
      },
      {
        input: "'Only-one'",
        output: ''
      }
    ]
  },
  {
    component: Simple,
    name: 'Часть определённой длины - substr',
    text: 'Дана строка \\(s\\). Выведите строку из трёх символов строки \\(s\\), начиная со второго, используя метод строки substr',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abcd'",
        output: 'bcd'
      },
      {
        input: "'Test string'",
        output: 'est'
      },
      {
        input: "'First-second'",
        output: 'irs'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подстрока с определённого индекса',
    text: 'Дана строка \\(s\\). Выведите часть строки \\(s\\), начиная с четвёртого символа до конца, с помощью substr',
    note: 'В этом примере substr ведёт себя так же, как slice',
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abcd'",
        output: 'd'
      },
      {
        input: "'Test string'",
        output: 't string'
      },
      {
        input: "'First-second'",
        output: 'st-second'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последние символы',
    text: 'Дана строка \\(s\\). Выведите часть строки \\(s\\), состоящую из её последних трёх символов',
    note: 'В этом примере substr ведёт себя так же, как slice',
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abcd'",
        output: 'bcd'
      },
      {
        input: "'Test string'",
        output: 'ing'
      },
      {
        input: "'First-second'",
        output: 'ond'
      }
    ]
  },
  {
    component: Simple,
    name: 'Замена',
    text: 'Дана строка \\(s\\). Замените первое вхождение подстроки \'sub\' на подстроку \'SUB\', используя метод replace',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'The sub for replace'",
        output: 'The SUB for replace'
      },
      {
        input: "'First substring or second sub'",
        output: 'First SUBstring or second sub'
      },
      {
        input: "'No one'",
        output: 'No one'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вставка перед подстрокой',
    text: 'Даны строки \\(s\\) и \\(ss\\). Перед первым вхождением подстроки \\(ss\\) вставить \'$\'',
    note: null,
    input: 'Cтроки \\(s\\) и \\(ss\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'$200 - 100 = 100', '100'",
        output: '$200 - $100 = 100'
      },
      {
        input: "'Pro100 string', 'string'",
        output: 'Pro100 $string'
      },
      {
        input: "'Prosto string', '100'",
        output: 'Prosto string'
      }
    ]
  },
  {
    component: Simple,
    name: 'Вставка после подстроки',
    text: 'Даны строки \\(s\\) и \\(ss\\). После первого вхождения подстроки \\(ss\\) в строке \\(s\\) вставить \'...\'',
    note: null,
    input: 'Cтроки \\(s\\) и \\(ss\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'$200 - 100 = 100', '100'",
        output: '$200 - 100... = 100'
      },
      {
        input: "'Pro100 string', 'string'",
        output: 'Pro100 string...'
      },
      {
        input: "'Prosto string', '100'",
        output: 'Prosto string'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удвоение вхождения',
    text: 'Даны строки \\(s\\) и \\(ss\\). Удвоить первое вхождение подстроки \\(ss\\) в строке \\(s\\)',
    note: null,
    input: 'Cтроки \\(s\\) и \\(ss\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abcabc', 'b'",
        output: 'abbcabc'
      },
      {
        input: "'test', 't'",
        output: 'ttest'
      },
      {
        input: "'1234', 'q'",
        output: '1234'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удаление подстроки',
    text: 'Даны строки \\(s\\) и \\(ss\\). Удалите первое вхождение подстроки \\(ss\\) в строке \\(s\\)',
    note: null,
    input: 'Cтроки \\(s\\) и \\(ss\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'$200 - 100 = 100', '$200 - '",
        output: '100 = 100'
      },
      {
        input: "'Pro100 string', '100'",
        output: 'Pro string'
      },
      {
        input: "'Prosto string', '100'",
        output: 'Prosto string'
      }
    ]
  },
  {
    component: Simple,
    name: 'Верхний и нижний регистры',
    text: 'Дана строка \\(s\\). В первой строке выведите \\(s\\) в нижнем регистре, во второй в верхнем. Используем методы строки toLowerCase и toUpperCase',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученные строки',
    examples: [
      {
        input: "'Simple String With Words'",
        output: 'simple string with words\nSIMPLE STRING WITH WORDS'
      },
      {
        input: "'Pro100 string'",
        output: 'pro100 string\nPRO100 STRING'
      },
      {
        input: "'Prosto STRING'",
        output: 'prosto string\nPROSTO STRING'
      }
    ]
  },
  {
    component: Simple,
    name: 'Определение регистра',
    text: 'Дана строка \\(s\\). Если все буквы в ней заглавные, выведите upper, если все строчные, выведите lower, если есть и те, и другие, выведите mixed',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Регистр',
    examples: [
      {
        input: "'ASD'",
        output: 'upper'
      },
      {
        input: "'fgh'",
        output: 'lower'
      },
      {
        input: "'Zxc'",
        output: 'mixed'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сколько слов с заглавной буквы',
    text: 'Дана строка \\(s\\), состоящая из слов, разделённых одиночными пробелами. Выведите количество слов, которые начинаются с заглавной буквы',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Количество слов с заглавной буквы',
    examples: [
      {
        input: "'Hello, world'",
        output: '1'
      },
      {
        input: "'John Smith'",
        output: '2'
      },
      {
        input: "'JohnSmith'",
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Исправление первой буквы',
    text: 'Дана строка \\(s\\). Если первая буква в ней строчная, то сделайте её заглавной',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'asd'",
        output: 'Asd'
      },
      {
        input: "'tEST'",
        output: 'TEST'
      },
      {
        input: "'Qwe'",
        output: 'Qwe'
      }
    ]
  },
  {
    component: Simple,
    name: 'Заполнение точками',
    text: 'Дана строка \\(s\\) и число \\(n\\). Дополните строку \\(s\\) до длины \\(n\\) двумя способами: добавив точки вначале и добавив точки в конце. Выведите две получившиеся строки через пробел. Используем методы padStart и padEnd',
    note: null,
    input: 'Cтрока \\(s\\) и  целое положительное \\(n\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'text', 7",
        output: '...text text...'
      },
      {
        input: "'long string', 3",
        output: 'long string long string'
      },
      {
        input: "'text', 4",
        output: 'text text'
      }
    ]
  },
  {
    component: Simple,
    name: 'Формат времени',
    text: 'Даны два числа \\(h\\) и \\(m\\) - часы и минуты. Выведите строку, содержащую это время в формате HH:MM',
    note: null,
    input: 'Целые числа \\(h\\) и \\(m\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: '8, 0',
        output: '08:00'
      },
      {
        input: '13, 5',
        output: '13:05'
      },
      {
        input: '9, 30',
        output: '09:30'
      },
      {
        input: '22, 45',
        output: '22:45'
      }
    ]
  },
  {
    component: Simple,
    name: 'Приведение к определённой длине',
    text: 'Дана строка \\(s\\) и число \\(n\\). Если длина строки больше \\(n\\), то оставьте только первые \\(n\\) символов, а если меньше, то добавьте точки в конце, чтобы длина стала \\(n\\)',
    note: null,
    input: 'Строка \\(s\\) и целое положительное число \\(n\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'abc', 6",
        output: 'abc...'
      },
      {
        input: "'abcdef', 4",
        output: 'abcd'
      },
      {
        input: "'abc', 3",
        output: 'abc'
      }
    ]
  },
  {
    component: Simple,
    name: 'Обрезание пробелов - trim',
    text: 'Дана строка \\(s\\). Выведите три строки. В первой нужно удалить пробелы в начале и в конце строки \\(s\\). Во второй только в начале. В третьей только в конце. К каждой строке добавьте точку в конце, чтобы видеть пробелы. Используются методы trim, trimStart, trimEnd',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Полученные строки',
    examples: [
      {
        input: "'   login   '",
        output: 'login.\nlogin   .\n   login.'
      },
      {
        input: "'   pass   word'",
        output: 'pass   word.\npass   word.\n   pass   word.'
      },
      {
        input: "'admin '",
        output: 'admin.\nadmin .\nadmin.'
      }
    ]
  },
  {
    component: Simple,
    name: 'Проверка логина',
    text: 'Дана строка \\(s\\). Если после обрезания пробелов строка равна \'admin\', то выведите true, иначе false',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'true или false',
    examples: [
      {
        input: "' admin'",
        output: 'true'
      },
      {
        input: "'admin '",
        output: 'true'
      },
      {
        input: "'admin'",
        output: 'true'
      },
      {
        input: "' a d m i n '",
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Другие методы строк',
    text: 'Дана строка \\(s\\). Выведите 4 строки. В первой строке выведите, начинается ли строка с \'Ab\', во второй - заканчивается ли на \'ing\', в третьей содержит ли подстроку \'so\'. В четвёртой выведите 5 дефисов. Используются методы строк startsWith, endsWith, includes и repeat',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'true или false',
    examples: [
      {
        input: "'Absolute'",
        output: 'true\nfalse\ntrue\n-----'
      },
      {
        input: "'String'",
        output: 'false\ntrue\nfalse\n-----'
      },
      {
        input: "'so, Ab is not in the begining'",
        output: 'false\ntrue\ntrue\n-----'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подсчёт совпадений',
    text: 'Даны строки \\(s\\), \\(start\\), \\(end\\) и \\(inc\\). Выведите три числа через пробел - сколько слов в строке \\(s\\) начинается с подстроки \\(start\\), сколько заканчивается на подстроку \\(end\\) и сколько содержит подстроку \\(inc\\) (слова разделены пробелами)',
    note: null,
    input: 'Строки \\(s\\), \\(start\\), \\(end\\), \\(inc\\)',
    output: 'Три числа через пробел',
    examples: [
      {
        input: "'abc abb bca', 'a', 'b', 'c'",
        output: '2 1 2'
      },
      {
        input: "'Abra cada bra Abra', 'ra', 'ra', 'ad'",
        output: '0 3 1'
      },
      {
        input: "'some text with some words', 'w', 'some', 't'",
        output: '2 2 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Камень-ножницы-бумага',
    text: "Вася и Петя решили сыграть в камень-ножницы-бумагу. Для простоты будем обозначать их буквами 'k', 'n' и 'b'. Выведите имя победителя или слово ничья.",
    note: 'Камень бьёт ножницы, ножницы бьют бумагу, бумага бьёт камень. Если две фигуры одинаковые, будет ничья.',
    input: "\\(v\\) - фигура Васи и \\(p\\) - фигура Пети. Каждый символ может быть либо 'k', либо 'n', либо 'b'",
    output: "Выведите одну строку - 'Вася', 'Петя' или 'ничья' (без кавычек)",
    examples: [
      {
        input: "'k', 'n'",
        output: 'Вася'
      },
      {
        input: "'b', 'n'",
        output: 'Петя'
      },
      {
        input: "'b', 'k'",
        output: 'Вася'
      },
      {
        input: "'n', 'n'",
        output: 'ничья'
      }
    ]
  },
  {
    component: Simple,
    name: 'Добавление слова',
    text: "Дана строка \\(s\\), состоящая из слов, разделённых одиночными пробелами. Если среди слов нет слова 'class', то добавить его в конец списка слов. Вывести все слова через пробел",
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'cclass classs'",
        output: 'cclass classs class'
      },
      {
        input: "'class'",
        output: 'class'
      },
      {
        input: "''",
        output: 'class'
      }
    ]
  },
  {
    component: Simple,
    name: 'Удаление слов',
    text: "Дана строка \\(s\\), состоящая из слов, разделённых одиночными пробелами. Если среди слов есть слово (одно или несколько) 'class', то удалить его. Вывести все слова через пробел",
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Полученная строка',
    examples: [
      {
        input: "'cclass classs'",
        output: 'cclass classs'
      },
      {
        input: "'class'",
        output: ''
      },
      {
        input: "'class test class'",
        output: 'test'
      }
    ]
  },
  {
    component: Simple,
    name: 'Расширение и имя файла',
    text: 'Дана строка \\(s\\), содержащая путь к файлу. Вывести расширение файла и его имя',
    note: null,
    input: 'Cтрока \\(s\\)',
    output: 'Расширение и имя файла через пробел',
    examples: [
      {
        input: "'/var/www/index.html'",
        output: 'html index'
      },
      {
        input: "'/var/log/log.01.tar.gz'",
        output: 'gz log.01.tar'
      },
      {
        input: "'Zadachi na stroki.js'",
        output: 'js Zadachi na stroki'
      }
    ]
  },
  {
    component: Simple,
    name: 'Получение кода символа - charCodeAt',
    text: 'Дана строка \\(s\\), состоящая из одного символа. Выведите код этого символа (номер в таблице ASCII), используя метод строки charCodeAt',
    note: 'Метод принимает индекс символа, код которого нужно узнать',
    input: 'Строка \\(s\\)',
    output: 'Код символа (целое число)',
    examples: [
      {
        input: "'a'",
        output: '97'
      },
      {
        input: "'c'",
        output: '99'
      },
      {
        input: "'A'",
        output: '65'
      },
      {
        input: "'Z'",
        output: '90'
      },
      {
        input: "'0'",
        output: '48'
      },
      {
        input: "'9'",
        output: '57'
      }
    ]
  },
  {
    component: Simple,
    name: 'Получение символа по коду - String.fromCharCode',
    text: 'Дано число \\(n\\). Выведите символ, соответствующий этому коду в таблице ASCII, используя функцию String.fromCharCode',
    note: null,
    input: 'Целое неотрицательное число \\(n\\)',
    output: 'Символ',
    examples: [
      {
        input: '97',
        output: 'a'
      },
      {
        input: '99',
        output: 'c'
      },
      {
        input: '65',
        output: 'A'
      },
      {
        input: '90',
        output: 'Z'
      },
      {
        input: '48',
        output: '0'
      },
      {
        input: '57',
        output: '9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Предыдущий и следующий символы',
    text: 'Дана строка \\(s\\) из одного символа. Выведите предыдущий символ из таблицы ASCII, а затем следующий',
    note: null,
    input: 'Строка \\(s\\)',
    output: 'Два символа через пробел',
    examples: [
      {
        input: "'b'",
        output: 'a c'
      },
      {
        input: "'Ю'",
        output: 'Э Я'
      },
      {
        input: "'N'",
        output: 'M O'
      },
      {
        input: "'3'",
        output: '2 4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Перебор символов',
    text: 'Дано целое число \\(n\\) (\\(1 \\le n \\le 26\\)). Выведите первые \\(n\\) букв латинского алфавита в нижнем регистре (строчные)',
    note: null,
    input: 'Целое положительное \\(n\\)',
    output: 'Каждая буква на новой строке',
    examples: [
      {
        input: '1',
        output: 'a'
      },
      {
        input: '3',
        output: 'a\nb\nc'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последние буквы алфавита',
    text: 'Дано целое число \\(n\\) (\\(1 \\le n \\le 26\\)). Выведите последние \\(n\\) букв латинского алфавита в верхнем регистре (заглавные)',
    note: null,
    input: 'Целое положительное \\(n\\)',
    output: 'Каждая буква на новой строке',
    examples: [
      {
        input: '1',
        output: 'Z'
      },
      {
        input: '3',
        output: 'X\nY\nZ'
      }
    ]
  },
  {
    component: Simple,
    name: 'Пары соседей',
    text: 'Дано целое число \\(n\\) (\\(n \\le 25\\)). Выведите первые \\(n\\) пар букв, соседних в алфавите, в верхнем регистре, начиная от AB. (AB, BC, CD...)',
    note: null,
    input: '\\(n\\) - целое положительное',
    output: 'Каждая пара слитно на новой строке',
    examples: [
      {
        input: '1',
        output: 'AB'
      },
      {
        input: '3',
        output: 'AB\nBC\nCD'
      }
    ]
  },
  {
    component: Simple,
    name: 'Схождение в центре',
    text: 'Даны строки \\(a\\) и \\(b\\) - строчные латинские буквы (\\(a < b\\)). Выведите пары букв из последовательности от \\(a\\) до \\(b\\): первая и последняя, затем вторая и предпоследняя, и т. д., пока не встретятся в центре (при нечётной длине диапазона центральный символ не включается)',
    note: "Например, даны 'd' и 'i'. Значит последовательность d e f g h i. Выводятся пары di, eh, fg",
    input: "\\(a\\) и \\(b\\) - латинские буквы от 'a' до 'z'",
    output: 'Каждая пара через пробел на новой строке',
    examples: [
      {
        input: "'a', 'c'",
        output: 'a c'
      },
      {
        input: "'b', 'e'",
        output: 'b e\nc d'
      },
      {
        input: "'d', 'i'",
        output: 'd i\ne h\nf g'
      }
    ]
  }
]
